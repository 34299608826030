import TopHeader from "../../components/header/TopHeader";
import SideHeader from '../../components/header/SideHeader';
import './Relatorio.css';
import {useNavigate} from "react-router-dom";
import {useState} from "react";


function Atendimento() {
    const navigate = useNavigate();
    const [navVisible, showNavbar] = useState(false);

    return (
        <div>
            <SideHeader visible={navVisible} show={showNavbar}/>
            <TopHeader/>
            <div className={!navVisible ? "page" : "page page-with-navbar"}>
                <div className="backButtonContainer">
                    <button class="backButton" onClick={() => {
                        navigate('/relatorios')
                    }}>Voltar
                    </button>
                </div>
                <br/>
                <h1 className='titulo_dash'>PPV Atendimento</h1>
                <div className="visibleIframeContainer">
                    <iframe title="PPV ATENDIMENTO" width="1140" height="541.25"
                            src="https://app.powerbi.com/view?r=eyJrIjoiNWUwYmY1MjItYzRkZS00ODVkLWJmMTMtMjJkOGRkMDU0YmJkIiwidCI6IjBhNDJlZGFiLWFmY2QtNDhhZi1hMGI5LTViNzhlZGIxNjRkYSJ9"
                            frameBorder='0' allowFullScreen></iframe>
                </div>
                <h1 className='titulo_dash'>Relatório de Vendas</h1>
                <div className="visibleIframeContainer">
                    <iframe title="Relatório de Vendas" width="1140" height="541.25"
                            src="https://app.powerbi.com/view?r=eyJrIjoiMmYzZjI4ODAtNTM0Yi00ODA0LWEwZTYtZGEzZDNhNjhmMzMzIiwidCI6IjBhNDJlZGFiLWFmY2QtNDhhZi1hMGI5LTViNzhlZGIxNjRkYSJ9 "
                            frameBorder="0" allowFullScreen="true"></iframe>
                </div>
            </div>
        </div>
    )
}

export default Atendimento;